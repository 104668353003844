import { classNames } from '@lib';

interface Props {
  intent?: 'edit' | 'wideEdit' | 'display';
  className?: string;
  children: any;
}

export default function Content({
  intent = 'display',
  className,
  children,
  ...props
}: Props) {
  return (
    <div
      className={classNames(
        'mx-auto',
        intent === 'edit' &&
          'mt-5 mb-24 max-w-xl bg-white px-8 py-8 md:mt-10 md:rounded-xl  md:px-12 md:py-10',
        intent === 'wideEdit' &&
          'mt-5 mb-24 max-w-6xl bg-white px-8 py-8 md:mt-10 md:rounded-xl  md:px-12 md:py-10',
        intent === 'display' && 'max-w-7xl px-6 lg:px-9',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
