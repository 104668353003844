import { classNames } from '@lib';

const DEBUG = false;

interface Props {
  className?: string;
  element?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'a'
    | 'span'
    | 'div';
  children: any;
  [x: string]: any;
}

export function Headline1Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Lexend text-8xl font-light tracking-[-1.5px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.Headline1Text` : children}
    </Container>
  );
}

export function Headline2Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Lexend text-6xl font-light tracking-[-0.5px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.Headline2Text` : children}
    </Container>
  );
}

export function Headline3Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames('font-Lexend text-5xl', className)}
      {...props}
    >
      {DEBUG ? `${element}.Headline3Text` : children}
    </Container>
  );
}

export function Headline4Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Lexend text-4xl tracking-[0.25px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.Headline4Text` : children}
    </Container>
  );
}

export function Headline5Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames('font-Lexend text-2xl', className)}
      {...props}
    >
      {DEBUG ? `${element}.Headline5Text` : children}
    </Container>
  );
}

export function Headline6Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Lexend text-xl font-medium tracking-[0.15px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.Headline6Text` : children}
    </Container>
  );
}

export function Subtitle1Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames('font-Lexend tracking-[0.15px]', className)}
      {...props}
    >
      {DEBUG ? `${element}.Subtitle1Text` : children}
    </Container>
  );
}

export function Subtitle2Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Lexend text-sm font-medium tracking-[0.1px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.Subtitle2Text` : children}
    </Container>
  );
}

export function Body1Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Inter font-light tracking-[0.5px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.Body1Text` : children}
    </Container>
  );
}

export function Body2Text({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames('font-Inter text-sm tracking-[0.25px]', className)}
      {...props}
    >
      {DEBUG ? `${element}.BodyText2` : children}
    </Container>
  );
}

export function ButtonText({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Inter text-sm font-medium tracking-[1.25px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.ButtonText` : children}
    </Container>
  );
}

export function CaptionText({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames('font-Inter text-xs tracking-[0.4px]', className)}
      {...props}
    >
      {DEBUG ? `${element}.CaptionText` : children}
    </Container>
  );
}

export function OverlineText({
  className,
  element = 'p',
  children,
  ...props
}: Props) {
  const Container = element;
  return (
    <Container
      className={classNames(
        'font-Inter text-xs uppercase tracking-[1.5px]',
        className,
      )}
      {...props}
    >
      {DEBUG ? `${element}.OverlineText` : children}
    </Container>
  );
}

export function LinkText({
  inverse,
  element = 'span',
  className = '',
  children,
  ...props
}: Props) {
  const Container = element;
  return <Container {...props}> {DEBUG ? 'LinkText' : children}</Container>;
}
