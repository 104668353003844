import { Transition } from '@headlessui/react';
import { classNames } from '@lib';
import { useCallback, useState } from 'react';
import IconOutline from './IconOutline';
import { Body1Text, ButtonText, CaptionText } from './Text';

interface ButtonProps {
  id: string;
  theme?: 'blue' | 'yellow' | 'green' | 'grey' | 'disabled' | 'darkgrey';
  size?: 'small' | 'standard' | 'large';
  statusIcon?: OutlineIcon;
  animateStatusIcon?: boolean;
  hoverIcon?: OutlineIcon;
  disabled?: boolean;
  className?: string;
  children?: any;
  onClick?: (event?: any) => void;
  [x: string]: any;
}

export function Button({
  id,
  theme = 'grey',
  size = 'standard',
  statusIcon,
  animateStatusIcon,
  hoverIcon,
  disabled = false,
  className,
  children,
  ...props
}: ButtonProps) {
  const [showHoverIcon, setShowHoverIcon] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setShowHoverIcon(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShowHoverIcon(false);
  }, []);

  return (
    <button
      id={id}
      className={classNames(
        'font-Lexend relative  select-none overflow-hidden ring-0 focus:outline-none',
        theme === 'grey' && 'border-greyMed  text-greyDk   border bg-white',
        theme === 'grey' &&
          !disabled &&
          'hover:border-greyDk hover:text-greyDk',
        theme === 'blue' &&
          'bg-blueMed from-blueLt/50 to-blueMed  border border-transparent text-white ',
        theme === 'yellow' &&
          'bg-orangeMed from-orangeLt/50 to-orangeMed border border-transparent text-black ',
        theme === 'green' &&
          'bg-tealDk from-tealMed/50 to-tealDk border border-transparent text-white',
        theme === 'darkgrey' &&
          'bg-greyDk from-greyMed/50 to-greyDk border-greyDk border text-white',
        theme === 'disabled' && 'border-greyMed  text-greyMed  border bg-white',
        !disabled && 'cursor-pointer hover:bg-gradient-to-t hover:shadow-md',
        disabled && 'cursor-not-allowed',
        !(hoverIcon || statusIcon) && size !== 'small' && 'min-w-[120px] px-6',
        (hoverIcon || statusIcon) && size !== 'small' && 'min-w-[150px] px-12',
        size === 'small' && 'w-20 rounded-full p-2 text-sm',
        size === 'standard' && 'block rounded-full py-3',
        size === 'large' && 'block rounded-xl py-4',
        className,
      )}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props}
    >
      <div className="flex flex-row">
        {/* status icon */}
        {statusIcon && (
          <div className="absolute left-3 flex-none self-center">
            <Transition
              show={!!statusIcon}
              appear={true}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <IconOutline
                id={statusIcon}
                className={classNames(
                  animateStatusIcon && 'animate-spin',
                  size === 'standard' && 'h-6 w-6 ',
                  size === 'large' && 'h-7 w-7',
                  theme === 'grey' && 'text-greyDk',
                  theme === 'blue' && 'text-white',
                  theme === 'yellow' && 'text-black',
                  theme === 'green' && 'text-white',
                  theme === 'darkgrey' && 'text-white',
                  theme === 'disabled' && 'text-greyMed',
                )}
              />
            </Transition>
          </div>
        )}

        {/* button label */}
        <div className="flex-grow">
          {size === 'small' ? (
            <CaptionText>{children}</CaptionText>
          ) : size === 'standard' ? (
            <ButtonText>{children}</ButtonText>
          ) : (
            children
          )}
        </div>

        {/* hover icon */}
        {hoverIcon && (
          <div className="absolute right-3 flex-none self-center">
            <Transition
              show={showHoverIcon && !disabled}
              enter="transition-transform duration-150  "
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transition-opacity duration-150 "
              leaveFrom="opacity-100 "
              leaveTo="opacity-0 "
            >
              <IconOutline
                id={hoverIcon}
                className={classNames(
                  size === 'standard' && 'h-6 w-6 ',
                  size === 'large' && 'h-7 w-7',
                  theme === 'grey' && 'text-greyDk',
                  theme === 'blue' && 'text-white',
                  theme === 'yellow' && 'text-black',
                  theme === 'green' && 'text-white',
                  theme === 'darkgrey' && 'text-white',
                )}
              />
            </Transition>
          </div>
        )}
      </div>
    </button>
  );
}

interface LargeButtonProps extends ButtonProps {
  label: string;
  description: string;
}

export function LargeButton({
  label,
  description,
  className,
  theme,
  ...props
}: LargeButtonProps) {
  return (
    <Button
      size="large"
      theme={theme}
      className={classNames('w-full', className)}
      {...props}
    >
      <Body1Text
        className={classNames(
          theme !== 'blue' && 'text-black',
          'mb-1 ml-2 text-left',
        )}
      >
        {label}
      </Body1Text>
      <Body1Text className="ml-2 text-left">{description}</Body1Text>
    </Button>
  );
}

export function IconButton({
  id,
  handleClick,
  icon,
  className,
  ...props
}: ButtonProps) {
  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover(true);
  };

  const handleHideHover = () => {
    setHover(false);
  };

  return (
    <button
      id={id}
      className="relative cursor-pointer"
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleHideHover}
    >
      <div className="relative z-10 cursor-pointer">
        <IconOutline
          id={icon}
          className={classNames('h-7 w-7 text-black', className)}
          {...props}
        />
      </div>
      <Transition
        show={hover}
        enter="transition-all duration-150"
        enterFrom="h-0 w-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-greyMed/50 rounded-full"
        enterTo="h-10 w-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-greyMed/50 rounded-full"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-10 w-10 bg-greyMed/50 rounded-full "
        leaveTo="opacity-0 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-10 w-10 bg-greyMed/50 rounded-full"
      ></Transition>
    </button>
  );
}

// interface OptionButtonProps {
//   id: string;
//   icon: OutlineIcon;
//   label: string;
//   description: string;
//   secondary?: boolean;
//   onClick: () => void;
//   onMouseEnter?: () => void;
//   [x: string]: any;
// }

// export function AnimatedOptionButton({
//   id,
//   icon,
//   label,
//   description,
//   secondary,
//   onClick,
//   onMouseEnter,
//   ...props
// }: OptionButtonProps) {
//   const [showIcon, setShowIcon] = useState(false);

//   const handleShowIcon = () => {
//     setShowIcon(true);
//     onMouseEnter && onMouseEnter();
//   };

//   const handleHideIcon = () => {
//     setShowIcon(false);
//   };

//   return (
//     <button
//       id={id}
//       className={classNames(
//         'relative w-full overflow-hidden rounded-lg p-4  hover:shadow-md',
//         secondary
//           ? 'border-greyMed  hover:border-greyDk border bg-white'
//           : 'bg-blueMed from-blueLt/30 to-blueMed hover:bg-gradient-to-t',
//       )}
//       tabIndex={-1}
//       onClick={onClick}
//       onMouseEnter={handleShowIcon}
//       onMouseLeave={handleHideIcon}
//     >
//       <div className="relative z-10 flex cursor-pointer flex-row">
//         <div className="flex-none self-center pr-4">
//           <IconOutline
//             id={icon}
//             className={classNames(
//               'h-8 w-8 ',
//               secondary ? 'text-greyDk' : 'text-white',
//             )}
//           />
//         </div>
//         <div className="flex-1">
//           <Body1Text
//             className={classNames(
//               'mb-1 text-left font-bold',
//               secondary ? 'text-black' : 'text-white',
//             )}
//           >
//             {label}
//           </Body1Text>
//           <Body1Text
//             className={classNames(
//               'text-left text-sm ',
//               secondary ? 'text-greyDk' : 'text-white',
//             )}
//           >
//             {description}
//           </Body1Text>
//         </div>
//         <div className="relative w-8 flex-none self-center">
//           <Transition
//             show={showIcon}
//             enter="transition-transform duration-150  "
//             enterFrom="translate-y-1/2 absolute"
//             enterTo="-translate-y-1/2 absolute"
//             leave="transition-opacity duration-300 "
//             leaveFrom="opacity-100 absolute -translate-y-1/2"
//             leaveTo="opacity-0 absolute -translate-y-1/2"
//           >
//             <IconOutline
//               id="arrow-right"
//               className={classNames(
//                 'h-8 w-8 ',
//                 secondary ? 'text-greyDk' : 'text-white',
//               )}
//             />
//           </Transition>
//         </div>
//       </div>
//     </button>
//   );
// }
