import {
  ArrowPathIcon,
  ArrowSmallRightIcon,
  BanknotesIcon,
  BeakerIcon,
  ChatBubbleBottomCenterTextIcon,
  CheckBadgeIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronUpDownIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  Cog6ToothIcon,
  ExclamationCircleIcon,
  GlobeAltIcon,
  HomeModernIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  MinusCircleIcon,
  PencilSquareIcon,
  PhoneArrowUpRightIcon,
  PlusCircleIcon,
  PlusIcon,
  QrCodeIcon,
  QuestionMarkCircleIcon,
  RocketLaunchIcon,
  SparklesIcon,
  TagIcon,
  TruckIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

interface Props {
  id: OutlineIcon;
  [x: string]: any;
}

export default function Icon({ id, ...rest }: Props) {
  switch (id) {
    case 'clipboard':
      return <ClipboardDocumentListIcon {...rest} />;

    // case 'ellipsis':
    //   return <EllipsisHorizontalIcon {...rest} />;

    // case 'up':
    //   return <ArrowSmallUpIcon {...rest} />;

    case 'arrow-left':
      return <ChevronLeftIcon {...rest} />;

    case 'arrow-right':
      return <ArrowSmallRightIcon {...rest} />;

    case 'badge-check':
      return <CheckBadgeIcon {...rest} />;

    case 'beaker':
      return <BeakerIcon {...rest} />;

    case 'book-open':
      return <QuestionMarkCircleIcon {...rest} />;

    case 'cash':
      return <BanknotesIcon {...rest} />;

    // case 'chat':
    //   return <ChatBubbleLeftEllipsisIcon {...rest} />;

    case 'check-circle':
      return <CheckCircleIcon {...rest} />;

    case 'check':
      return <CheckIcon {...rest} />;

    case 'clock':
      return <ClockIcon {...rest} />;

    case 'cog':
      return <Cog6ToothIcon {...rest} />;

    // case 'cube':
    //   return <CubeIcon {...rest} />;

    case 'exclamation-circle':
      return <ExclamationCircleIcon {...rest} />;

    // case 'eye-off':
    //   return <EyeSlashIcon {...rest} />;

    // case 'eye':
    //   return <EyeIcon {...rest} />;

    // case 'gift':
    //   return <GiftIcon {...rest} />;

    // case 'lightning-bolt':
    //   return <BoltIcon {...rest} />;

    // case 'mail':
    //   return <EnvelopeIcon {...rest} />;

    case 'refresh':
      return <ArrowPathIcon {...rest} />;

    case 'selector':
      return <ChevronUpDownIcon {...rest} />;

    // case 'shield-check':
    //   return <ShieldCheckIcon {...rest} />;

    // case 'volume-up':
    //   return <BellIcon {...rest} />;

    // case 'volume-off':
    //   return <BellSlashIcon {...rest} />;

    case 'expand':
      return <PlusCircleIcon {...rest} />;

    case 'collapse':
      return <MinusCircleIcon {...rest} />;

    case 'xmark':
      return <XMarkIcon {...rest} />;

    case 'sparkles':
      return <SparklesIcon {...rest} />;

    case 'users':
      return <UsersIcon {...rest} />;

    case 'tag':
      return <TagIcon {...rest} />;

    case 'megaphone':
      return <MegaphoneIcon {...rest} />;

    case 'qr-code':
      return <QrCodeIcon {...rest} />;

    case 'money':
      return <BanknotesIcon {...rest} />;

    case 'chat-bubble':
      return <ChatBubbleBottomCenterTextIcon {...rest} />;

    case 'search':
      return <MagnifyingGlassIcon {...rest} />;

    case 'plus':
      return <PlusIcon {...rest} />;

    case 'pencil':
      return <PencilSquareIcon {...rest} />;

    case 'house':
      return <HomeModernIcon {...rest} />;

    case 'truck':
      return <TruckIcon {...rest} />;

    case 'phone-arrow':
      return <PhoneArrowUpRightIcon {...rest} />;

    case 'globe':
      return <GlobeAltIcon {...rest} />;

    case 'rocket':
      return <RocketLaunchIcon {...rest} />;

    default:
      throw new Error(`Missing icon ${id}`);
  }
}
